'use client';

import { logClientError } from '@/lib/logger';
import { Calculator as UICalculator } from '@swordhealth/ui-corporate';
import { useCallback, useEffect, useId, useReducer } from 'react';

// to set a max width for the bars
const MAX_BAR_VALUE = 17000000;

function mapFilterList(cmsDropdownList, values) {
  return cmsDropdownList.map((filter) => {
    const name = filter.name;
    const options = values?.[filter.name] || [{ value: '', label: 'Select an option' }];

    return {
      ...filter,
      options,
      id: `calculator-${name}`,
    };
  });
}

function mapValueList(cmsValueList, values) {
  return cmsValueList.map((value, i) => {
    const numericValue = values?.[i] || 0;

    return {
      ...value,
      percentage: Math.ceil((numericValue * 100) / MAX_BAR_VALUE),
      value: numericValue || '',
    };
  });
}

function createInitialState(valueList, filterList) {
  return {
    loading: true,
    valueList: mapValueList(valueList),
    formDropdownList: mapFilterList(filterList),
  };
}

function calculatorReducer(state, action) {
  switch (action.type) {
    case 'updateFilters': {
      return {
        ...state,
        formDropdownList: mapFilterList(state.formDropdownList, action.value),
      };
    }
    case 'updateValues': {
      return {
        ...state,
        valueList: mapValueList(state.valueList, action.value),
        loading: false,
      };
    }
    case 'toggleLoading': {
      return {
        ...state,
        loading: action.value,
      };
    }
  }
}

export default function CalculatorRegionIndustry({
  valueList: cmsValueList,
  formSlider,
  formDropdownList: cmsDropdownList,
  ...rest
}) {
  const sectionId = useId();
  const [{ valueList, formDropdownList, loading }, updateData] = useReducer(
    calculatorReducer,
    createInitialState(cmsValueList, cmsDropdownList),
  );
  const handleChange = useCallback(async (values) => {
    try {
      updateData({ type: 'toggleLoading', value: true });

      const res = await fetch('/api/calculator/roi-industry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await res.json();

      updateData({ type: 'updateValues', value: data });
    } catch (error) {
      updateData({ type: 'toggleLoading', value: false });
    }
  }, []);

  useEffect(() => {
    const updateViews = async () => {
      try {
        const filterDefaults = cmsDropdownList.reduce((list, dropdown) => {
          list[dropdown.name] = dropdown.defaultValue;

          return list;
        }, {});

        const res = await fetch(
          `/api/calculator/roi-industry?${new URLSearchParams({ employees: formSlider.defaultValue, ...filterDefaults }).toString()}`,
        );
        const data = await res.json();

        updateData({ type: 'updateFilters', value: data.filters });
        updateData({ type: 'updateValues', value: data.initialValues });
      } catch (error) {
        logClientError(error);
      }
    };

    updateViews();
  }, [cmsDropdownList]);

  return (
    <UICalculator
      {...rest}
      loading={loading}
      onFormUpdate={handleChange}
      formSlider={
        formSlider
          ? {
              ...formSlider,
              step: formSlider?.min,
              name: 'employees',
              id: `${sectionId}-employees`,
            }
          : undefined
      }
      valueList={valueList}
      formDropdownList={formDropdownList}
    />
  );
}

const DefaultSEO = {
  defaultTitle: 'Sword Health',
  titleTemplate: '%s | Sword Health',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://swordhealth.com',
    site_name: 'Sword Health',
  },
};

const socialMediaLinks = {
  twitter: 'https://x.com/swordhealth',
  linkedin: 'https://www.linkedin.com/company/swordhealth',
  instagram: 'https://www.instagram.com/sword.health/',
  facebook: 'https://www.facebook.com/SWORDHealth1',
  youtube: 'https://www.youtube.com/@sword_health',
};

const SEOMetadata = {
  baseCanonical: DefaultSEO.openGraph.url,
  logo: '/assets/logo/sword-logo.png',
};

export { DefaultSEO, SEOMetadata, socialMediaLinks };

import styled, { css } from 'styled-components';

const NavItem = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 15px;
  line-height: 40px;
  letter-spacing: 0;
  padding: 20px 0 19px;
  transition: all 0.3s ease;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    ${(props) =>
      props.$white &&
      !props.$button &&
      css`
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      `};
  }

  &.enroll {
    margin-right: 0;
  }

  &.enroll > * {
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};

    &:hover {
      color: ${(props) => props.theme.colors.grey.dark};
      background-color: rgba(255, 255, 255, 0.9);
    }

    ${(props) =>
      (!props.$dynamicNavbarLayout || props.$white) &&
      css`
        color: ${(props) => props.theme.colors.grey.dark};
        border-color: ${(props) => props.theme.colors.grey.dark};
        &:hover {
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.grey.dark};
        }
      `}
  }

  & a:not([class]) {
    cursor: pointer;

    ${(props) =>
      props.$active &&
      css`
        &:before {
          opacity: 1;
        }
      `}

    ${(props) =>
      (!props.$dynamicNavbarLayout || props.$white) &&
      css`
        color: ${(props) => props.theme.colors.grey.dark};
      `}
  }

  ${(props) =>
    props.$superMenuItem &&
    css`
      color: ${props.theme.colors.white};
    `};

  ${(props) =>
    (!props.$dynamicNavbarLayout || props.$white) &&
    css`
      color: ${(props) => props.theme.colors.grey.dark};
    `};

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-right: ${(props) => (props.$lastItem ? '0' : '32px')};

    ${(props) =>
      props.$active &&
      css`
        opacity: 1;
      `}

    & a {
      &:hover {
        &:before {
          opacity: 1;
        }
        opacity: 100%;
      }
    }
    &.request-demo {
      margin-left: auto;
      margin-right: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 24px;
    padding: 32px 36px;
    color: ${(props) => props.theme.colors.grey.dark};
    position: relative;
    text-align: left;
    &.enroll > * {
      color: ${(props) => props.theme.colors.grey.dark};
      border-color: ${(props) => props.theme.colors.grey.dark};
    }

    &.request-demo > * {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.primary.default};
    }

    & a {
      &:before {
        bottom: 1px;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 1px;
      background-color: rgba(31, 34, 44, 0.1);
      left: 36px;
      bottom: 0;
      right: 36px;
    }

    &.enroll,
    &.request-demo {
      width: 50vw;
      border: none;
      padding-bottom: 48px;
      background-color: #fff;
      a {
        width: 100%;
        text-align: center;
      }
      &:before {
        display: none;
      }
    }
    &.request-demo {
      left: 0;
      padding-right: 8px;
    }
    &.enroll {
      right: 0;
      padding-left: 8px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    font-size: 20px;
    line-height: 35px;
    padding: 24px;
    &:before {
      left: 24px;
      right: 24px;
    }
    &.enroll,
    &.request-demo {
      position: fixed;
      padding: 0 24px;
      width: 100vw;
      left: unset;
      right: unset;
    }
    &.enroll {
      bottom: 21px;
    }
    &.request-demo {
      bottom: 77px;
    }
  }
`;

export default NavItem;

import styled from 'styled-components';

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 0;
  }
`;

export default SocialWrapper;
